
import VueCookie from "vue-cookie";
import { ElMessage } from "element-plus";

export const getOpenid = async () => {
  const openid = VueCookie.get("openid");
  if (openid) {
    return openid;
  }

  const result = await window.mplogin({
    scope: "snsapi_userinfo",
    appid: "wx4e325659335ad4c9",
    envid: "prod-3g2we1tse07b6dde",
    noback: true,
  });
  if (result.ret === 0) {
    const { data: { success, data = {} } = {} } =
      await result.cloud.callContainer({
        path: "/user/wechatRegister.json",
        method: "POST",
        header: {
          "X-WX-SERVICE": "springboot-hupp",
        },
      });

    if (success && data.openId) {
      VueCookie.set("openid", data.openId, { expires: "365d" });
      return data.openId;
    }
  }
  ElMessage.error("获取用户信息失败");
  // TODO: mock
  VueCookie.set("openid", 'oN4h-6Jidsj-N6-HISM3sNMbPWMY', { expires: "365d" });
  return 'oN4h-6Jidsj-N6-HISM3sNMbPWMY';
//   return '';
};

