<template>
  <!-- logo信息 -->
  <div class="app-info">
    <img class="app-logo" src="~images/logo.jpg" alt="" />
    <div class="app-name">{{ APP_NAME }}</div>
  </div>
</template>

<script>
import { APP_NAME } from "@/config";

export default {
  name: "FooterInfo",
  data() {
    return {
      APP_NAME,
    };
  },
};
</script>

<style scoped lang="scss">
.app-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: #cbcbcb;
  .app-logo {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
    filter: grayscale(100%);
    opacity: 0.5;
  }
  .app-name {
    margin-left: 6px;
    font-size: 0.3rem;
    font-weight: bold;
  }
}
</style>