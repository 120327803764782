
// 基础路由
export default [
  {
    path: '/',
    component: () => import('@/views/Layout'),
    children: [
      {
        path: '/home',// 策略商城
        name: "Home",
        meta: { scrollY: 0 },
        component: () => import('@/views/home/index')
      },
      {
        path: '/coupon', // 券码兑换
        name: "coupon",
        meta: { scrollY: 0 },
        component: () => import('@/views/coupon/index/index'),
        children: [
          {
            path: '/coupon/exchange',
            name: "CouponExchange",
            component: () => import('@/views/coupon/exchange')
          },
        
        ]
      },

      {
        path: '/profile', // 个人中心
        name: "Profile",
        meta: { scrollY: 0 },
        component: () => import('@/views/profile/index/index'),
        children: [
          {
            path: '/profile/information',
            name: "ProfileInformation",
            component: () => import('@/views/profile/information/index')
          },
          {
            path: '/profile/author',
            name: "AboutAuthor",
            component: () => import('@/views/profile/aboutus/author')
          },
          {
            path: '/profile/feedback',
            name: "Feedback",
            component: () => import('@/views/profile/feedback')
          },
          {
            path: '/profile/project',
            name: "AboutProject",
            component: () => import('@/views/profile/aboutus/project')
          },
          {
            path: '/profile/setting',
            name: "Setting",
            component: () => import('@/views/profile/setting/index')
          },
        ]
      },
    ]
  },
]