import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import main from './main'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },

  ...main,


  { // 详情页面
    path: '/detail',
    name: "Detail",
    component: () => import('@/views/coupon/detail')
  },

  // 注册登录
  {
    path: '/login',
    name: "Login",
    component: () => import('@/views/account/login')
  },
  {
    path: '/register',
    name: "Register",
    component: () => import('@/views/account/register')
  },
  {
    path: '/forget',
    name: "Forget",
    component: () => import('@/views/account/forget')
  },
  // 离线
  {
    path: '/offline',
    name: "Offline",
    component: () => import('@/views/offline')
  },
  // 页面未找到
  {
    path: '/:catchAll(.*)',
    name: "NotFound",
    component: () => import('@/views/404')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // 在切换路由时滚动到页面顶部
  window.scrollTo(0, 0);
  next();
});
export default router
