<template>
  <div class="panel">
    <div class="panel-header" @click="routerTo">
      <div class="panel-title">
        <span>{{ title }}</span>
      </div>
      <div class="panel-more">
        <slot name="subtitle" v-if="$slots.subtitle"></slot>
        <span v-else>{{ subtitle }}</span>
        <FontIcon v-if="to" iconName="a-jiantou-you" :size="15"></FontIcon>
      </div>
    </div>

    <div class="panel-content" :class="{ 'scroll-x': scrollX }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "MPanel",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    replace: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
    },
    scrollX: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    routerTo() {
      if (this.to) {
        if (this.replace) {
          this.$router.replace(this.to);
        } else {
          this.$router.push(this.to);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.panel {
  margin: 0.3rem;
  padding-bottom: 0.3rem;
  background-color: #fff;
  border-radius: 0.24rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .panel-header {
    padding: 0.3rem 0.3rem 0 0.3rem;
    font-size: 0.4rem;
    color: #222;
    display: flex;
    align-items: center;
    .panel-title {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        &::before {
          content: "";
          vertical-align: middle;
          display: inline-block;
          margin-top: -4px;
          margin-right: 0.1rem;
          width: 0.1rem;
          height: 0.42rem;
          border-radius: 0.2rem;
          background-color: $color-theme;
        }
      }
    }
    .panel-more {
      font-size: 0.3rem;
      padding-left: 8px;
      color: #333;
      display: inline-block;
      .m-icon {
        vertical-align: top;
      }
    }
  }

  .panel-content {
    width: 100%;
    &.scroll-x {
      padding: 0 0.4rem;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>