import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";
import store from "./store";
import { APP_NAME } from "./config";
// import wx from 'weixin-js-sdk';

import FontIcon from '@/components/common/FontIcon.vue'

const app = createApp(App)

// app.use(wx)
// import {
//   Table,
//   TableColumn,
//   Tag,
//   Button,
//   ButtonGroup,
//   Popover,
//   Dialog,
//   Form,
//   FormItem,
//   Input,
//   Select,
//   Option
// } from "element-ui";

import "./assets/styles/index.css";
// import "mind-ui-vue/lib/index.css";
// import "element-ui/lib/theme-chalk/index.css";


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


app.use(ElementPlus)
app.component('FontIcon', FontIcon)

// UI 组件库
// import MindUI from "mind-ui-vue";
// app.use(MindUI);

// // Element UI组件库
// app.use(Table);
// app.use(TableColumn);
// app.use(Tag);
// app.use(Button);
// app.use(ButtonGroup);
// app.use(Popover);
// app.use(Dialog);
// app.use(Form);
// app.use(FormItem);
// app.use(Input);
// app.use(Select);
// app.use(Option);


// px2rem
import "lib-flexible/flexible";

// 上拉加载指令
import infiniteScroll from "./directive/infinite-scroll/index";
app.use(infiniteScroll);

// 懒加载
// import VueLazyload from "vue-lazyload";
// app.use(VueLazyload);

// 页眉
import HeaderBar from "@/components/Header/HeaderBar/index";
app.use(HeaderBar);

// fixed 子页面
import Page from "@/components/Page";
app.use(Page);

// 控制面板
import MPanel from "@/components/MPanel";
app.use(MPanel);

import StrategyPanel from "@/components/StrategyPanel";
app.use(StrategyPanel);

// app info
import FooterInfo from "@/components/App/FooterInfo";
app.use(FooterInfo);

// 无数据
import NoData from "@/components/NoData";
app.use(NoData);

import PhotoSwiper from "./components/Photo/PhotoSwiper/index";
app.use(PhotoSwiper);

// 举报
import Report from "./components/Report/index";
app.use(Report);

// loading skeleton
import Skeleton from "./components/Skeleton/index";
app.use(Skeleton);



// 阻止页面滚动
import { preventScroll } from "./util/index";
app.config.globalProperties.$preventScroll = preventScroll;

// 应用名称
app.config.globalProperties.$APP_NAME = APP_NAME;

app.config.productionTip = false;

app.use(store).use(router).mount('#app')
