<template>
  <div class="panel">
    <div class="panel-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "StrategyPanel",
};
</script>

<style scoped lang="scss">
.panel {
  margin: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 12px;

  .panel-content {
    text-align: center;
    width: 100%;
    &.scroll-x {
      padding: 0 22px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>