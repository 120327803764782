<template>
  <div
    class="template-icon-box"
    :style="styleObj"
    :class="{ readonly: readonly, 'symbol-icon': type === 'symbol' }"
  >
    <svg v-if="type === 'symbol'" class="icon" aria-hidden="true">
      <use :xlink:href="`#${iconClass}`"></use>
    </svg>
    <i :style="styleObj" v-else class="iconfont" :class="[iconClass]"></i>
  </div>
</template>
<script>
export default {
  name: "FontIcon",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 16,
    },
    type: {
      // NOTE: 本项目icon规范为symbol（svg）形式，iconfont未配置icon字体包
      // class单色，symbol多色
      type: String,
      default: "class",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      return `icon-${this.iconName}`;
    },
    styleObj() {
      return { "font-size": `${this.size}px` };
    },
  },
  methods: {
    routerTo() {},
  },
};
</script>

<style lang="scss">
.symbol-icon {
  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.template-icon-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.readonly {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
