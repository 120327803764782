import { createStore } from 'vuex'
import user from './modules/user'
import movie from './modules/movie'
import actor from './modules/actor'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
		user,
		movie,
		actor
	}
})
